import React, { Fragment, useEffect, useState } from "react";
import CompanyLayout from "../../../Components/Company/Layouts";
import styles from "../../../Components/Scss/Admin.module.scss";
import IconImage from "../../../Public/admin-images/form-icon-image.png";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import Modal from "../../../Components/Modal/modal";
import Loader from "../../../Components/Global/widget/Loader";
import { isEmpty } from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import { inputValidation } from "../../../Utils/Validatior";
import Axios from "../../../Config/AxiosInstance";
import TempPINPasswordModal from "../../../Components/Modal/TempPINPasswordModal";
import { getRoles } from "../../../api";
import {compact} from "lodash/array";

export default function EditUser({ permission }) {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { token, selectedStoreIds, isAllStoreSelected, userInfo } = useAuth();
  const {stores} = userInfo;
  const [currentStoreId, setCurrentStoreId] = useState("");
  let { id } = useParams();
  const route = useNavigate();
  const [updatePin, setUpdatePin] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [roleName, setRoleName] = useState([]);
  const [data, setData] = useState();
  const [userData, setUserData] = useState([]);

  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preSelectedStore, setPreSelectedStore] = useState({});
  const [tipsInWallet, setTipsInWallet] = useState(false);
  const [state, setState] = useState([]);

  const isSelectStoreAvailable =
    isAllStoreSelected &&
    userData?.data?.some((field) => field.name === "store") &&
    options.length >= 2 &&
    userData?.parentKey &&
    !["company_user", "company_user_1"].includes(userData?.parentKey);


  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);

  useEffect(() => {
    setLoading(true);
    Axios(token)
      .post(`/user/details/${id}`)
      .then((res) => {
        setLoading(false);
        setTipsInWallet(res?.data?.response?.response?.tips_to_wallet);
      })
      .then((e) => {
        setLoading(false);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    try {
      if (token && currentStoreId && id) {
        setLoading(true);
        Axios(token)
          .get(`/edit/user/${id}`)
          .then(async (response) => {
            setLoading(false);
            if (response.data.response) {
              const res = response.data.response;
              res.data.map((store) => {
                if (store.name === "store") {
                  const strArr = store.value?.map((st) => {
                    if (st?.externalId) {
                      return st;
                    }
                  });
                  setPreSelectedStore(compact(strArr));
                }
              });
              setUserData(res);
              setState(res);
              setLoading(true);
              await getRoles(token, userInfo.role, {
                stores: selectedStoreIds,
                isAllStoreSelected: isAllStoreSelected,
              }).then((roles) => {
                setLoading(false);
                if (!roles) {
                  return;
                }

                const rolesData = roles.map((role, index) => ({
                  id: index + 1,
                  name: role.name,
                  externalId: role.id,
                  slug: role.slug,
                }));

                setRoleName(rolesData);
              });

              setLoading(true);
              await Axios(token)
                .post(`/all/stores`, {
                  stores: selectedStoreIds,
                  isAllStoreSelected: isAllStoreSelected,
                })
                .then((response) => {
                  setLoading(false);
                  if (response.data.response) {
                    const storeArray = [];
                    response.data.response.map((items) => {
                      storeArray.push({
                        name: items?.store_name,
                        externalId: items?.store_external_id,
                      });
                    });
                    setOptions(storeArray);
                  }
                });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [token, currentStoreId]);
  const OnRoleChange = async (e) => {
    const dataInput = e.target.value;
    setLoading(true);
    try {
      const checkRole = await Axios(token).post(`/check/role/${id}`, {
        role: dataInput,
        type: "update",
      });
      setLoading(false);
      const data = checkRole.data;
      data?.field.map((field) => {
        const index = state.data.findIndex((object) => {
          return object.id === field.id;
        });
        if (index > -1) {
          field.value = state.data[index].value;
        }
      });
      if (
        data.parentKey === "associate" ||
        data.parentKey === "terminal" ||
        data.parentKey === "driver" ||
        data.parentKey === "dispatcher"
      ) {
        setPreSelectedStore([]);
      } else {
        state.data.map((store) => {
          if (store.name === "store") {
            const strArr = store.value?.map((st) => {
              if (st?.externalId) {
                return st;
              }
            });
            setPreSelectedStore(compact(strArr));
            console.log(compact(strArr));
          }
        });
      }

      setUserData({
        ...userData,
        data: data.field,
        parentKey: data.parentKey,
        parentID: data.parentID,
        role: data.role,
      });
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    let storeExId = [];
    selectedItem.user_id = id;
    selectedItem.type = "create";
    selectedList.map((items) => {
      storeExId.push(items);
    });
    setPreSelectedStore(storeExId);
  };
  const onRemove = (selectedList, removedItem) => {
    let storeExId = [];
    removedItem.user_id = id;
    removedItem.type = "remove";
    selectedList.map((items) => {
      storeExId.push(items);
    });
    setPreSelectedStore(storeExId);
  };

  const submitHandler = (e) => {
    try {
      e.preventDefault();
      let error = false;
      if (isEmpty(userData.parentKey)) {
        setError("Select a role first");
        setIsModalOpen(true);
        return;
      }
      userData.data.map((item) => {
        if (
          item.id === "firstName" &&
          isEmpty(inputValidation(item.value)) &&
          userData.parentKey !== "terminal"
        ) {
          setError("First name is Required!");
          error = true;
        }
        if (
          item.id === "lastName" &&
          isEmpty(inputValidation(item.value)) &&
          userData.parentKey !== "terminal"
        ) {
          setError("Last name is Required!");
          error = true;
        }
        if (
          item.id === "username" &&
          isEmpty(inputValidation(item.value)) &&
          userData.parentKey !== "associate"
        ) {
          setError("Username is required.");
          error = true;
        }
      });
      if (
        isSelectStoreAvailable &&
        currentStoreId.length > 1 &&
        isEmpty(preSelectedStore)
      ) {
        setError("Select a store");
        error = true;
      }
      if (
        isSelectStoreAvailable &&
        userData.parentKey === "associate" &&
        preSelectedStore?.length > 1
      ) {
        setError("Select a store");
        error = true;
      }
      if (error) {
        setIsModalOpen(true);
        return;
      }

      let data = {};

      userData.data.map((item) => {
        if (item.id === "firstName") {
          data.firstName = item?.value; //inputValidation(item?.value)
        }
        if (item.id === "lastName") {
          data.lastName = item?.value; //inputValidation(item?.value)
        }
        if (data.parentKey !== "terminal" && item.id === "pin") {
          data.pin = updatePin;
        }
        if (data.parentKey === "terminal" && item.id === "location") {
          data.location = item?.value;
        }
        if (data.parentKey === "terminal" && item.id === "deviceName") {
          data.deviceName = item?.value;
        }
        if (data.parentKey !== "associate" && item.id === "username") {
          data.username = item?.value; //inputValidation(item?.value)
        }
        if (data.parentKey !== "associate" && item.id === "password") {
          data.password = updatePassword; //inputValidation(item?.value)
        }
      });

      data.parentKey = inputValidation(userData.parentKey);
      data.role = userData.role;
      data.store =
        isEmpty(preSelectedStore) && currentStoreId.length > 0
          ? selectedStoreIds
          : preSelectedStore;
      data.external_id = id;
      data.tipsInWallet = tipsInWallet;
      setLoading(true);
      Axios(token)
        .post("/update/user", data)
        .then(function (response) {
          setLoading(false);

          if (
            response?.data?.status &&
            (response.data?.temp || response.data?.tempp)
          ) {
            setData(response?.data);
            setConfirmationModal(true);
          } else {
            route(`/users`);
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setError(error.response.data.message);
            setIsModalOpen(true);
          }
        });
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  const onInputChange = async (e) => {
    const index = userData.data.findIndex((object) => {
      return object.id === e.target.name;
    });
    userData.data[index].value = e.target.value;
    setUserData({ ...userData });
  };

  const currentStore = stores.find((store) => currentStoreId.includes(store.id));

  return (
    <>
      {loading && <Loader />}
      {isModalOpen && (
        <Modal
          isModalOpen={isModalOpen}
          type={"Error"}
          message={error}
          showOk={true}
          modalCallBack={() => setIsModalOpen(false)}
        />
      )}
      {confirmationModal && data && (
        <TempPINPasswordModal
          data={data}
          isUpdate={true}
          isModalOpen={confirmationModal}
        />
      )}

      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          <div className="md:max-w-[75vw] mt-4 mx-auto">
            <div className="md:pl-8 xl:col-span-10 lg:col-span-9 md:col-span-8">
              <div className="relative md:pb-6 lg:items-center lg:flex-row">
                <div className={`${styles.form_card} md:px-5 md:pt-0 pt-5`}>
                  <div className="">
                    <div className={` flex items-center`}>
                      <div
                        className={`w-16 h-16 p-4 rounded-full bg-yellow-400`}
                      >
                        <img src={IconImage} alt="" />
                      </div>
                      <div className={`pl-4`}>
                        <p className="text-lg font-semibold">Edit User</p>
                        <p className={`text-gray-600 text-sm`}>
                          To edit an account please enter the information.
                        </p>
                      </div>
                    </div>
                    <div className="pt-16">
                      <form
                        onSubmit={submitHandler}
                        className="grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-3"
                      >
                        <div className="">
                          <label className={`pb-2 w-full block`}>
                            Select Role
                          </label>
                          <select
                            name={`role`}
                            id={`role`}
                            value={userData?.role}
                            onChange={OnRoleChange}
                            className={`${styles.form_control} outline-none`}
                          >
                            <option value={0}>Select Role</option>
                            {roleName?.map(function (items, i) {
                              return (
                                <option
                                  value={`${items.externalId}:${items.slug}`}
                                  key={i}
                                >
                                  {items.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {userData?.data?.map((field, index) => {
                          if (field.name === "store") {
                            if (!isSelectStoreAvailable) {
                              return null;
                            }

                            const className = [
                              "associate",
                              "terminal",
                            ].includes(userData.parentKey)
                              ? ""
                              : "col-span-2";

                            return (
                              <div className={className} key={index}>
                                <div className="col-span-1">
                                  <label className="Select pb-2 w-full block">
                                    Accounts
                                  </label>
                                  <Multiselect
                                    className={styles.storeMultiselect}
                                    options={options}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    selectedValues={preSelectedStore}
                                    showCheckbox={field.isMultiple}
                                    placeholder="Select Store"
                                    displayValue="name"
                                    name="store"
                                    singleSelect={!field.isMultiple}
                                  />
                                </div>
                              </div>
                            );
                          }

                          if (field.name === "password") {
                            return (
                              <div key={index}>
                                <label className="pb-2 w-full block" />
                                <div className="flex h-full items-center justify-between mr-3 align-items-center">
                                  <p className="pb-2 text-sm w-full block">
                                    Update Password
                                  </p>
                                  <div className={styles.switch_box}>
                                    <input
                                      type="checkbox"
                                      id="updatePassword"
                                      defaultChecked={updatePassword}
                                      name="updatePassword"
                                      onChange={(e) =>
                                        setUpdatePassword(e.target.checked)
                                      }
                                      className={styles.input_switch}
                                    />
                                    <label htmlFor="updatePassword" />
                                    <div className={styles.switch_status_box} />
                                  </div>
                                </div>
                              </div>
                            );
                          }

                          if (field.name === "pin") {
                            return (
                              <div key={index}>
                                <label className="pb-2 w-full block" />
                                <div className="flex h-full items-center justify-between">
                                  <p className="pr-2 text-sm text-gray-600">
                                    Update PIN
                                  </p>
                                  <div className={styles.switch_box}>
                                    <input
                                      type="checkbox"
                                      id="updatePIN"
                                      defaultChecked={updatePin}
                                      name="updatePIN"
                                      onChange={(e) =>
                                        setUpdatePin(e.target.checked)
                                      }
                                      className={styles.input_switch}
                                    />
                                    <label htmlFor="updatePIN" />
                                    <div className={styles.switch_status_box} />
                                  </div>
                                </div>
                              </div>
                            );
                          }

                          return (
                            <div key={index}>
                              <div>
                                <label className="pb-2 w-full block">
                                  {field.title}
                                </label>
                                <input
                                  type={field.inputType}
                                  name={field.name}
                                  id={field.id}
                                  value={field.value || ""}
                                  maxLength={field?.maxLength}
                                  minLength={field?.minLength}
                                  className={`${styles.form_control} mb-3`}
                                  onChange={onInputChange}
                                />
                              </div>
                            </div>
                          );
                        })}
                        {!isAllStoreSelected && currentStore?.enable_budtender_tips && (
                          <div>
                            <label className="pb-2 w-full block"/>
                            <div className="flex h-full items-center justify-between">
                              <p className="pr-2 text-sm text-gray-600">
                                Receive tips in wallet
                              </p>
                              <div className={styles.switch_box}>
                                <input
                                  type="checkbox"
                                  id="updateWallet"
                                  defaultChecked={tipsInWallet}
                                  checked={tipsInWallet}
                                  name="updateWallet"
                                  onChange={(e) =>
                                    setTipsInWallet(e.target.checked)
                                  }
                                  className={styles.input_switch}
                                />
                                <label htmlFor="updateWallet"/>
                                <div className={styles.switch_status_box}/>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="pt-4 col-span-full">
                          <button
                            type="submit"
                            className="w-full bg-green-500 text-white uppercase tracking-wider font-semibold rounded py-3 px-4 text-base hover:bg-green-700 transition-all disabled:bg-green-400"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised/>
      )}
    </>
  );
}
