import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useNavigate } from "react-router-dom";
import Table from "../../../Components/Global/Table";
import { useAuth } from "../../../Context/AuthContext";
import CreateRoleModal from "../../../Components/Modal/CreateRoleModal";
import CompanyLayout from "../../../Components/Company/Layouts";
import ViewRoleModal from "../../../Components/Modal/ViewRoleModal";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import DuplicateRoleModal from "../../../Components/Modal/DuplicateRoleModal";
import { orderBy } from "lodash";
import Axios from "../../../Config/AxiosInstance";

function Roles({ permission }) {
  const { token, selectedStoreIds, isAllStoreSelected } = useAuth();

  const [role, setRole] = useState("");
  const [data, setData] = useState([]);
  const [modelOpen, setModalOpen] = useState(false);
  const [isViewRoleModal, setIsViewRoleModal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [viewRoleId, setViewRoleId] = useState();

  const [duplicateRole, setDuplicateRole] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [tableLoading, setTableLoading] = useState(false);

  const router = useNavigate();
  useEffect(() => {
    setCurrentStoreId(selectedStoreIds);
  }, [selectedStoreIds]);
  const columns = [
    {
      name: "#",
      selector: (row) => row.serialNo,
      width: "70px",
    },
    {
      name: "Role Name",
      selector: (row) => row.role_name,
    },

    {
      name: "Role Type",
      selector: (row) => row.role_type,
      cell: (row) => [
        <div
          key={row.id + 1}
          className={` ${
            row.role_type === "Default"
              ? "bg-slate-100 text-slate-600"
              : "bg-green-100 text-green-500"
          } px-3 py-1 rounded-full leading-5 mr-3`}
        >
          {row.role_type}
        </div>,
      ],
    },
    {
      cell: (row) => [
        <button
          key={row.external_id + 1}
          className={` bg-green-500 text-white px-3 py-2 leading-5 rounded mr-3`}
          onClick={() => viewRole(row.external_id)}
          id={row.external_id + 1}
        >
          View
        </button>,

        <button
          disabled={!!row.isDefault}
          key={row.external_id + 3}
          className={`bg-green-500 text-white px-3 disabled:bg-green-300 py-2 leading-5 rounded mr-3`}
          onClick={() => assignPermission(row.external_id)}
          id={row.external_id + 3}
        >
          Edit
        </button>,
        // <button
        //   // disabled={!!row.isDefault}
        //   key={row.external_id + 3}
        //   className={`bg-red-500 text-white px-3 disabled:bg-red-300 py-2 leading-5 rounded mr-3`}
        //   onClick={() => handleDeleteRole(row.external_id)}
        //   id={row.external_id + 3}
        // >
        //   Delete
        // </button>,
        <button
          disabled={row.role_slug === "terminal"}
          key={row.external_id + 4}
          className={`bg-green-500 text-white px-3 disabled:bg-green-300 py-2 leading-5 rounded`}
          onClick={() => createDuplicateRole(row)}
          id={row.external_id + 4}
        >
          Duplicate
        </button>,
      ],
      name: "Action",
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      width: "350px",
      paddingRight: "20px",
    },
  ];
  const viewRole = (id) => {
    setViewRoleId(id);
    setIsViewRoleModal(true);
  };
  const createDuplicateRole = (row) => {
    setEditModalOpen(true);
    setDuplicateRole(row);
  };

  const assignPermission = (id) => {
    router(`/permission/${id}`);
  };

  useEffect(() => {
    let storeRoles = [];
    if (currentStoreId && token && !modelOpen) {
      //ref.current.continuousStart();
      setTableLoading(true);
      Axios(token)
        .post(`/roles`, { stores: currentStoreId })
        .then((r) => {
          if (r.data && r.data.response) {
            const allRoles = r.data.response;
            allRoles.map((role, index) => {
              let getRoles = {
                id: role.id,
                external_id: role.external_id,
                serialNo: index + 1,
                date: role.createdAt,
                role_name: role.name,
                role_slug: role.slug,
                role_type: !role.isGlobal ? "Default" : "Custom",
                isDefault: role.isDefault,
                reporting: role.reporting,
                isGlobal: role.isGlobal,
                noOfUser: 0,
                userNo: 12,
                permission: 5,
                assignPermission: "Assign Permissions",
              };
              storeRoles.push(getRoles);
            });
            const sortRoles = orderBy(storeRoles, ["role_type"], ["desc"]);
            setData(sortRoles);
          }
          //ref.current.complete();
          setTableLoading(false);
        });
    }
  }, [token, currentStoreId, role, modelOpen]);
  const handleCreateRole = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };
  const handleSavedData = (data) => {
    setRole(data);
  };

  return (
    <>
      {HasPermission(permission) ? (
        <CompanyLayout onStoreChange={(val) => setCurrentStoreId(val)}>
          {modelOpen && (
            <CreateRoleModal
              data={handleSavedData}
              storeId={currentStoreId}
              isAllSelected={isAllStoreSelected}
              isNew={isNew}
              onClose={(val) => {
                setModalOpen(val);
                setIsNew(true);
                setRole("");
              }}
            />
          )}

          {editModalOpen && (
            <DuplicateRoleModal
              data={handleSavedData}
              duplicateData={duplicateRole}
              storeId={currentStoreId}
              onClose={() => {
                setEditModalOpen(false);
                setRole("");
              }}
            />
          )}

          {isViewRoleModal && (
            <ViewRoleModal
              id={viewRoleId}
              onCloseViewModal={(val) => {
                setIsViewRoleModal(val);
              }}
            />
          )}

          <div className={`${styles.table_ui_style}`}>
            <div className="flex items-center justify-between">
              <p className="text-xl font-semibold">All Roles</p>

              <button
                onClick={handleCreateRole}
                className={`px-2 py-2 md:py-2.5 md:px-5 text-sm text-white flex items-center rounded bg-green-500 focus:bg-green-700 hover:bg-green-700 right-0 top-0 border-0 outline-none`}
              >
                Create Role
              </button>
            </div>

            <div className={`grid md:grid-cols-12 mt-5`}>
              <div
                className={` xl:col-span-12 lg:col-span-12 md:col-span-12 overflow-x-auto`}
              >
                <div className={`col-span-12`}>
                  <Table
                    perPage={10}
                    data={data}
                    showPagination={true}
                    columns={columns}
                    pending={tableLoading}
                    isNoRowsPerPage={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </>
  );
}

export default Roles;
