import { endOfDay, isValid, parse, parseISO, startOfDay, sub } from "date-fns";
import { format, toDate, toZonedTime } from "date-fns-tz";

// Standard date-fns formats to try when parsing
const dateFormats = [
  "yyyy-MM-dd",
  "MM/dd/yyyy",
  "dd/MM/yyyy",
  "MM-dd-yyyy",
  "dd-MM-yyyy",
  "yyyy/MM/dd",
  "yyyy.MM.dd",
  "dd.MM.yyyy",
  "MM.dd.yyyy",
  "MMMM do, yyyy",
  "MMMM d, yyyy",
  "MMM d, yyyy",
  "MMM do, yyyy",
  "MMMM dd, yyyy",
  "MM/dd/yy",
  "dd/MM/yy",
  "MM-dd-yy",
  "dd-MM-yy",
  "yyyy-M-d",
  "d-M-yyyy",
  "d/MM/yyyy",
  "MM/d/yyyy",
  "do MMMM yyyy",
  "dd MMM yyyy",
  "MMM d, yyyy",
  "MMM dd, yyyy",
  "PPPP",
  "PPPPpppp",
  "PPP",
  "PP",
  "P",
  "p",
  "MMM dd, yyyy h:mm a",
  "MMMM dd, yyyy h:mm a",
  "MMM dd, yyyy h:mm aaaa",
  "MMMM dd, yyyy h:mm aaaa",
];

// Moment.js to date-fns format mapping
const momentToDateFns = {
  'LT': 'h:mm a',
  'LTS': 'h:mm:ss a',
  'L': 'MM/dd/yyyy',
  'l': 'M/d/yyyy',
  'll': 'MMM d, yyyy',
  'LL': 'MMMM d, yyyy',
  'lll': 'MMM d, yyyy h:mm a',
  'LLL': 'MMMM d, yyyy h:mm a',
  'llll': 'ddd, MMM d, yyyy h:mm a',
  'LLLL': 'dddd, MMMM d, yyyy h:mm a',
};

class LocalTimeConverter {
  constructor(date = new Date(), isUTC = false) {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.date = this.parseDate(date, isUTC);
    if (!isValid(this.date)) {
      throw new Error(`Invalid date: ${date}`);
    }
  }

  static utc(date = new Date()) {
    return new LocalTimeConverter(date, true);
  }

  parseDate(date, isUTC) {
    // Handle Date objects
    if (date instanceof Date) {
      return isUTC ? toDate(date) : toZonedTime(date, this.timeZone);
    }

    // Handle string dates
    if (typeof date === 'string') {
      // First try parsing as ISO
      let parsedDate = parseISO(date);
      if (isValid(parsedDate)) {
        return isUTC ? toDate(parsedDate) : toZonedTime(parsedDate, this.timeZone);
      }

      // Try parsing with each format in the dateFormats array
      for (let formatString of dateFormats) {
        parsedDate = parse(date, formatString, new Date());
        if (isValid(parsedDate)) {
          return isUTC ? toDate(parsedDate) : toZonedTime(parsedDate, this.timeZone);
        }
      }
    }

    throw new Error(`Unable to parse date: ${date}`);
  }

  startOf(unit) {
    if (unit === "day") {
      this.date = startOfDay(this.date);
    }
    return this;
  }

  endOf(unit) {
    if (unit === "day") {
      this.date = endOfDay(this.date);
    }
    return this;
  }

  subtract(amount, unit) {
    this.date = sub(this.date, { [unit]: amount });
    return this;
  }

  format(formatStr = "yyyy-MM-dd") {
    try {
      let dateFnsFormat = formatStr;

      // Handle moment.js formats
      if (formatStr.includes(' ')) {
        // Handle combined formats (e.g., "ll LT")
        dateFnsFormat = formatStr.split(' ')
          .map(part => momentToDateFns[part] || part)
          .join(' ');
      } else {
        // Handle single format
        dateFnsFormat = momentToDateFns[formatStr] || formatStr;
      }

      // Handle legacy format strings
      dateFnsFormat = dateFnsFormat
        .replace(/YYYY/g, "yyyy")
        .replace(/DD/g, "dd")
        .replace(/YY/g, "yy");

      return format(this.date, dateFnsFormat, { timeZone: this.timeZone });
    } catch (error) {
      console.error(`Format error for "${formatStr}":`, error);
      // Fallback to a safe format
      return format(this.date, "MMM d, yyyy h:mm a", { timeZone: this.timeZone });
    }
  }
}

const convertToLocalTime = (date = new Date(), dateFormat = null) => {
  const converter = new LocalTimeConverter(date);
  return dateFormat ? converter.format(dateFormat) : converter;
};

export default convertToLocalTime;
