import React, { useEffect, useState } from "react";
import styles from "../../Components/Scss/Admin.module.scss";
import LinechartCard from "../../Components/Global/widget/LineChartCard";
import { useAuth } from "../../Context/AuthContext";
import { isEmpty, toNumber } from "lodash";
import CompanyLayout from "../../Components/Company/Layouts";
import Transaction from "../../Components/Global/transaction/transaction";
import { HasPermission } from "../../Components/Global/Permission";
import Unauthorised from "../Global/Unauthorised";
import { currencyFormat } from "../../Utils/Common";
import AuthorisedForPayment from "../Global/AuthorisedForPayment";
import Axios from "../../Config/AxiosInstance";
// import pusherSub from "../../Utils/Pusher";
import convertToLocalTime from "../../Utils/timeZoneConverter";
import useWebSocket from "../../hooks/websocket";

function Dashboard() {

  const { userInfo, token, selectedStoreIds, selectedDateList } = useAuth();
  const [cardData, setCardData] = useState();
  const [hiddenChart, setHiddenChart] = useState(true);
  const [analyticsFrom, setAnalyticsFrom] = useState(selectedDateList[0]);
  const [analyticsTo, setAnalyticsTo] = useState(selectedDateList[1]);
  const [analyticDatePick, setAnalyticDatePick] = useState();
  const [socketTrigger, setSocketTrigger] = useState(false);
  const transactionFor = "all";

  const [resData, setResData] = useState(false);
  const [state, setState] = useState({});
  const [userType, setUserType] = useState("");
  const [analytics, setAnalytics] = useState({
    processed: [],
    processedDate: [],
    customer: [],
    customerDate: [],
    sales: [],
    salesDate: [],
    tips: [],
    tipsDate: [],
    balance: [],
    balanceDate: [],
    return: [],
    returnDate: [],
    transactions: [],
    transactionsDate: [],
  });
  const [isUserHasPaymentPermission, setIsUserHasPaymentPermission] = useState(false);
  const { status, subscribe } = useWebSocket(
    token,
    [`company:${userInfo?.company_id}`]
  );

  useEffect(() => {
    if (status === 'connected') {
      const unsubscribe = subscribe('payment:updated', () => { //data
        setSocketTrigger(true);
        getAnalytics(selectedStoreIds, token, "store")
        setSocketTrigger(false);
      });

      return () => unsubscribe();
    }
  }, [status, subscribe]);

  useEffect(() => {
    if (userInfo) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
      const isUserHasPaymentPermission =
        userInfo?.permissions?.includes("make_standard_payment") ||
        userInfo?.permissions?.includes("make_contactless_payment");
      setIsUserHasPaymentPermission(isUserHasPaymentPermission);
    }
  }, [userInfo]);

  const getAnalytics = async (ID, token, userType) => {
    if (ID) {
      setResData(false);

      const data = await Axios(token).post(`/dashboard/analytics`, {
        stores: ID,
        from: analyticsFrom,
        to: analyticsTo,
        type: userType,
        transactionFor: transactionFor,
      });
      if (data) {
        setResData(true);
        setState(data.data.total);
        setAnalytics(data.data.analytics);
      }
    }
  };

  useEffect(() => {
    if (selectedStoreIds && token && !isEmpty(userType)) {
      Axios(token)
        .post(`/dashboard/analytics/others`, {
          stores: selectedStoreIds,
          from: analyticsFrom,
          to: analyticsTo,
          type: userType,
          transactionFor: transactionFor,
        })
        .then((res) => {
          let sorted = res.data?.response.tips.slice().sort(function (a, b) {
            return a - b;
          });
          setAnalytics({
            ...analytics,
            tips: res.data?.response ? sorted : [],
          });
          setState({
            ...state,
            Tips: res.data?.response ? res.data?.response.totalTips : 0,
          });
        })
        .catch(() => {});
    }
  }, [userType]);

  // useEffect(() => {
  //   pusherSub(toString(userInfo?.company_id), "transaction", () => {
  //     setCallPusher(true);
  //   });
  // }, []);

  useEffect(() => {
    if ((token && selectedStoreIds && analyticDatePick)) {
      getAnalytics(selectedStoreIds, token, "store")
    }
  }, [token, analyticDatePick, selectedStoreIds]);

  useEffect(() => {
    if (state || analytics) {
      setCardData([
        {
          name: "Sales Amount",
          amount: `${currencyFormat(toNumber(state?.Sales))}`,
          cardhight: 100,
          data: analytics?.sales,
          dataDate: [],
          startDateTime: analyticsFrom,
          endDateTime: analyticsTo,
        },
        {
          name: "Tip",
          amount: `${currencyFormat(toNumber(state?.Tips))}`,
          cardhight: 100,
          data: analytics?.tips,
          dataDate: [],
          startDateTime: analyticsFrom,
          endDateTime: analyticsTo,
          hasMenu: true,
          menu: ["all", "driver", "Associate", "dispatcher", "others"],
        },
        {
          name: "Sales Amount + Tip",
          amount: `${currencyFormat(toNumber(state?.Balance))}`,
          cardhight: 100,
          data: analytics?.balance,
          dataDate: [],
          startDateTime: analyticsFrom,
          endDateTime: analyticsTo,
        },
        {
          name: "Average Sales Amount",
          amount: `${currencyFormat(
            toNumber(state?.AverageSalesAmountAnalyticsamount),
          )}`,
          cardhight: 100,
          data: analytics?.AverageSalesAmountAnalytics,
          dataDate: [],
          startDateTime: analyticsFrom,
          endDateTime: analyticsTo,
        },
        {
          name: "Transactions (Excluding Full Refunds)",
          amount: `${state?.transactions}`,
          cardhight: 100,
          data: analytics?.transactions,
          dataDate: [],
          startDateTime: analyticsFrom,
          endDateTime: analyticsTo,
        },
        {
          name: "Returns",
          amount: `${currencyFormat(toNumber(state?.Return))}`,
          cardhight: 100,
          data: analytics?.return,
          dataDate: [],
          startDateTime: analyticsFrom,
          endDateTime: analyticsTo,
        },
      ]);
    }
  }, [state, analytics]);

  const HandelHiddenChart = (e) => {
    const hiddenChartVal = e.target.checked;
    setHiddenChart(hiddenChartVal);
  };

  const filterAnalyticsDate = (e) => {
    setAnalyticsFrom(convertToLocalTime(e[0]).format("YYYY-MM-DD"));
    setAnalyticsTo(convertToLocalTime(e[1]).format("YYYY-MM-DD"));
    setAnalyticDatePick(e);
  };

  return (
    <CompanyLayout>
      {HasPermission(["show_dashboard_transactions"]) ||
      HasPermission(["show_analytics"]) ? (
        <>
          {HasPermission(["show_analytics"]) && (
            <>
              <div className="flex justify-between my-2 flex-wrap">
                <div className="mr-auto  flex ">
                  <h5
                    className={`text-xl md:text-2xl font-semibold mr-4 sm:pb-0 text-gray-700`}
                  >
                    Dashboard
                  </h5>
                </div>
                <div className="flex items-center mr-0 ml-0 sm:ml-2">
                  <p className="pr-2 text-gray-600 hidden sm:block">
                    {hiddenChart ? "Disable" : "Enable"} Chart
                  </p>
                  <div className={`${styles.switch_box}`}>
                    <input
                      type="checkbox"
                      id="HiddenChartId"
                      checked={hiddenChart}
                      name="HiddenChartId"
                      onChange={(e) => HandelHiddenChart(e)}
                      className={` ${styles.input_switch}`}
                    />
                    <label htmlFor="HiddenChartId" className={`mb-0`} />
                    <div className={` ${styles.switch_status_box}`}></div>
                  </div>
                </div>
              </div>
              {hiddenChart && (
                <div>
                  {resData ? (
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                      {cardData?.map((cardItems, i) => {
                        return (
                          <div key={i}>
                            <LinechartCard
                              color={"#33b7ea"}
                              data={cardItems.data}
                              dataDate={cardItems.dataDate}
                              price={cardItems.amount}
                              height={cardItems.cardhight}
                              name={cardItems.name}
                              startDateTime={cardItems.startDateTime}
                              endDateTime={cardItems.endDateTime}
                              hasMenu={cardItems?.hasMenu}
                              menu={cardItems?.menu}
                              onMenuClick={(val) => {
                                setUserType(val);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-3 sm:grid-cols-2">
                      {Array.from(Array(6), (e, i) => {
                        return (
                          <div
                            key={i}
                            className={`border border-slate-100 rounded animate-pulse h-44 flex flex-col justify-between`}
                          >
                            <div className="px-4 pt-3 ">
                              <div>
                                <p className="flex w-1/2 h-2 pb-1 text-xs bg-gray-200 rounded"></p>
                                <h2 className="flex w-1/4 h-4 pb-1 mt-3 text-xs bg-gray-200 rounded"></h2>
                              </div>
                            </div>
                            <div
                              className={`flex justify-between -mt-7 text-xs text-gray-400 pb-3 ml-5 relative border-t border-slate-100 mr-3`}
                            >
                              <small className="w-10 h-2 mt-2 bg-gray-200 rounded"></small>
                              <small className="w-10 h-2 mt-2 bg-gray-200 rounded"></small>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {HasPermission(["show_dashboard_transactions"]) && (
            <div className={`${hiddenChart ? "sm:mt-10 mt-5" : "mt-1"}`}>
              <div className={` ${styles.table_ui_style}`}>
                <div className={`${styles.table_ui_style}`}>
                  {selectedStoreIds && (
                    <Transaction
                      selectedStoreId={selectedStoreIds}
                      token={token}
                      userInfo={userInfo}
                      selectedDateList={selectedDateList}
                      onFilterChange={filterAnalyticsDate}
                      socketTrigger={socketTrigger}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : isUserHasPaymentPermission ? (
        <>
          <AuthorisedForPayment
            headLine={`Authorised User`}
            message={`You only have permission to make payments. Please click on terminal button to make payment`}
          />
        </>
      ) : (
        <Unauthorised />
      )}
    </CompanyLayout>
  );
}

export default Dashboard;
