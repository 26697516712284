import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { toNumber } from "lodash";
import Axios from "../../Config/AxiosInstance";
import styles from "../../Components/Scss/Admin.module.scss";
import IconImage from "../../Public/admin-images/form-icon-image.png";
import CompanyLayout from "../../Components/Company/Layouts";
import RefundModal from "../../Components/Modal/RefundModal";
import NoData from "../../Components/Global/widget/NoData";
import Unauthorised from "../Global/Unauthorised";
import { useAuth } from "../../Context/AuthContext";
import { HasPermission } from "../../Components/Global/Permission";
import { currencyFormat, roundUp } from "../../Utils/Common";
import convertToLocalTime from "../../Utils/timeZoneConverter";


const TransactionDetailsContent = ({
                                     data,
                                     orderId,
                                     associateName,
                                     date,
                                     chargebacks,
                                     otherTransactions,
                                     refund,
                                     refundableToCard,
                                     handleRefund,
                                     lastTransition,
                                     handleNavigatePage,
                                     showComplianceFee,
                                     complianceFee,
                                     setShowComplianceFee,
                                   }) => (
  <div className="grid md:grid-cols-4 gap-x-7 xl:col-span-12 md:col-span-12">
    <div className="col-span-2">
      <TransactionInfo
        data={data}
        orderId={orderId}
        associateName={associateName}
        date={date}
        refund={refund}
        refundableToCard={refundableToCard}
        handleRefund={handleRefund}
        lastTransition={lastTransition}
        handleNavigatePage={handleNavigatePage}
      />
      <Timeline
        chargebacks={chargebacks}
        otherTransactions={otherTransactions}
        data={data}
        refund={refund}
        associateName={associateName}
      />
    </div>
    <div className="col-span-2">
      <PaymentInformation
        data={data}
        orderId={orderId}
        associateName={associateName}
        refund={refund}
        showComplianceFee={showComplianceFee}
        complianceFee={complianceFee}
        setShowComplianceFee={setShowComplianceFee}
      />
    </div>
  </div>
);

const TransactionInfo = ({
                           data,
                           orderId,
                           date,
                           refund,
                           refundableToCard,
                           handleRefund,
                           lastTransition,
                           handleNavigatePage,
                         }) => (
  <div className="items-center md:pt-8 pb-4 md:pb-8 md:py-5 mb-3 bg-white px-3 rounded-lg shadow-lg shadow-slate-100">
    <div className="flex items-center justify-between pb-3 border-b">
      <p className="flex py-2 text-sm text-gray-500">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
          />
        </svg>
        <span className="pl-2 text-gray-700 ">{orderId}</span>
      </p>
      {HasPermission(["perform_refund"]) && (
        <div className="flex justify-end">
          <button
            onClick={() => {
              handleRefund("card");
            }}
            disabled={
              toNumber(
                roundUp(data?.order_amount || 0) +
                roundUp(data?.BudtenderTip?.tips_amount || 0) -
                refund,
              ) <= 0
            }
            className={`${
              toNumber(refundableToCard) >= 0 ? "bg-green-500" : "bg-green-500"
            } flex items-center px-2 py-1.5 md:py-2.5 text-xs text-white  border-0 rounded outline-none ml-3 disabled:bg-gray-400 disabled:cursor-no-drop`}
          >
            <span>Refund</span>
          </button>
        </div>
      )}
    </div>
    <div className={`flex justify-between mt-1 md:mt-4`}>
      <div className={`flex items-center`}>
        <div
          className={`lg:w-20 lg:h-20 w-11 md:w-14 h-11 md:h-14 lg:p-5 p-3 rounded-full bg-green-500`}
        >
          <img src={IconImage} alt="" />
        </div>
        <div className="pl-2 md:pl-5 ml-2 md:ml-5 border-l">
          <p className="text-lg font-bold lg:text-3xl ">
            {currencyFormat(toNumber(data?.amount) + toNumber(data?.tip))}
          </p>
          {toNumber(refund) >= toNumber(data?.amount) + toNumber(data?.tip) ? (
            <p className="text-sm font-semibold text-right text-gray-500 ">
              Refunded
            </p>
          ) : refund ? (
            <p className="text-sm font-semibold text-right text-gray-500 ">
              Partially Refunded
            </p>
          ) : (
            <p className="text-sm font-semibold text-left text-green-500 ">
              Success
            </p>
          )}
        </div>
      </div>
      <div className="">
        <p className="mt-2 font-semibold text-gray-400">Date: {date}</p>
        <Menu
          as="div"
          className="relative inline-block text-left focus:bg-transparent"
        >
          <div>
            <Menu.Button className="relative flex items-center pt-4 text-sm text-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <span className="pl-2 text-gray-700 capitalize">
                {data?.Customer?.fullName?.toLowerCase()}
              </span>
              <span className="pl-2 text-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </Menu.Button>
          </div>
          <Transition as={Fragment}>
            <Menu.Items className="absolute right-0 z-30 w-64 p-2 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg shadow-slate-200 focus:outline-none">
              {lastTransition && (
                <div>
                  {lastTransition.map((items, index) => (
                    <Link
                      to={`/transaction/${items.external_id}`}
                      key={index}
                      className="relative flow-root px-2 py-3 transition-all bg-opacity-50 border-b hover:bg-indigo-50"
                    >
                      <span className="flex items-center">
                        <span className="text-sm text-gray-700">
                          Payment{" "}
                          <span className="font-semibold">
                            ${toNumber(items.amount).toFixed(2)}
                          </span>
                        </span>
                      </span>
                      <p className="block mt-1 text-xs text-gray-500">
                        {items.date}
                      </p>
                    </Link>
                  ))}
                  {lastTransition.length >= 5 && (
                    <button
                      className="w-full pt-3 pb-2 text-sm text-center text-indigo-500 outline-none"
                      onClick={handleNavigatePage}
                    >
                      See all activity
                    </button>
                  )}
                </div>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  </div>
);

const Timeline = ({ chargebacks, otherTransactions, data, associateName }) => (
  <div>
    <p className="pt-3 pb-1 text-lg font-semibold">Timeline</p>
    <div className="border-t">
      <ul className={styles.timeline_step}>
        {chargebacks.map((item, i) => (
          <TimelineItem key={i} item={item} type="chargeback" />
        ))}
        {otherTransactions.map((items, i) => (
          <TimelineItem key={i} item={items} type="transaction" />
        ))}
        {data?.BudtenderTip?.tips_amount && (
          <TimelineItem
            item={{
              amount: data?.BudtenderTip?.tips_amount,
              date: data?.BudtenderTip?.createdAt,
              associateName: associateName,
              type: "tip",
            }}
            type="tip"
          />
        )}
        {(data?.from_card_amount || 0) > 0 && (
          <TimelineItem
            item={{
              amount: data?.from_card_amount,
              date: data?.createdAt,
              type: "from_card",
            }}
            type="from_card"
          />
        )}
        {(data?.from_wallet_amount || 0) > 0 && (
          <TimelineItem
            item={{
              amount: data?.from_wallet_amount,
              date: data?.createdAt,
              type: "from_wallet",
            }}
            type="from_wallet"
          />
        )}
        <TimelineItem item={data} type="payment_succeeded" />
      </ul>
    </div>
  </div>
);

const TimelineItem = ({ item, type, data }) => {
  let iconColor = "text-green-500";
  let label = "";
  switch (type) {
    case "chargeback":
      iconColor = "text-red-500";
      label = `Chargeback - ${item?.chargeback_status?.toUpperCase()}`;
      break;
    case "transaction":
      iconColor =
        item.type === "REFUND" || item.type === "VOID"
          ? "text-red-500"
          : "text-green-500";
      label = `${currencyFormat(item?.amount || 0)} successfully ${
        item?.type === "REFUND" ? "refunded" : "void"
      } ${item?.refund_for === "WALLET" ? "to wallet" : "to card"} ${
        item?.refund_for !== "WALLET" && toNumber(item?.amount) >= toNumber(data?.amount) + toNumber(data?.tip)
          ? `(including ${currencyFormat(item?.fee || 0)} customer fee)`
          : ""
      }`;
      break;
    case "tip":
      iconColor = "text-yellow-500";
      label = `${currencyFormat(item.amount)} Tip Sent to ${
        item.associateName
      }`;
      break;
    case "from_card":
    case "from_wallet":
      iconColor = "text-[#008000]";
      label = `${currencyFormat(item.amount)} From ${
        type === "from_card" ? "Payment Source" : "Wallet"
      }`;
      break;
    case "payment_succeeded":
      iconColor = "text-[#008000]";
      label = "Payment Succeeded";
      break;
    default:
      break;
  }
  return (
    <li className="flex pt-3 pb-4">
      <div
        className={`p-1 mt-1 ${iconColor} bg-white rounded-full shadow w-7 h-7`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
          />
        </svg>
      </div>
      <div className="ml-2 mr-6">
        <span className="text-sm font-semibold">{label}</span>
        <span className="block text-gray-500 text-sm">
          {convertToLocalTime(
            item?.date || item?.createdAt || item?.activity_date,
          ).format("PPP")}
        </span>
      </div>
    </li>
  );
};

const PaymentInformation = ({ data, orderId, associateName, refund }) => (
  <div className="p-3 md:p-6 py-2 md:py-4 rounded-lg bg-slate-100 bg-opacity-40">
    <div className="flex items-center justify-between">
      <p className="mb-3 text-lg font-semibold ">Payment Information</p>
    </div>
    <div className="p-2 md:px-0 text-gray-600 bg-white rounded">
      <PaymentInformationRow
        label={
          data?.payment_type !== "cashout" ? "Order ID:" : "TRANSACTION ID:"
        }
        value={orderId}
      />
      <PaymentInformationRow
        label="Employee Name:"
        value={
          data?.is_global_associate ? (
            <Link
              to={`/user/${data?.Associate?.associate_external_id}`}
              className="text-sm font-semibold text-right text-green-500 capitalize"
            >
              {associateName}
            </Link>
          ) : (
            <Link
              to={`/associate/${data?.Associate?.associate_external_id}`}
              className="text-sm font-semibold text-right text-green-500 capitalize"
            >
              {associateName}
            </Link>
          )
        }
      />
      <PaymentInformationRow
        label="Store Name:"
        value={data?.Store?.store_name}
      />
      {data?.payment_type !== "cashout" && (
        <>
          <PaymentInformationRow
            label="SALES AMOUNT:"
            value={currencyFormat(toNumber(data?.amount))}
          />
          <PaymentInformationRow
            label="TIP:"
            value={currencyFormat(toNumber(data?.tip))}
          />
          <PaymentInformationRow
            label="TOTAL SALES plus tip AMOUNT:"
            value={currencyFormat(toNumber(data?.amount) + toNumber(data?.tip))}
          />
          <PaymentInformationRow
            label="CUSTOMER FEE:"
            value={currencyFormat(toNumber(data?.LoadFund?.fees || 0))}
          />
        </>
      )}
      <PaymentInformationRow
        label={
          data?.payment_type !== "cashout"
            ? "TOTAL PROCESSED AMOUNT:"
            : "TOTAL CASHOUT AMOUNT"
        }
        value={currencyFormat(toNumber(data?.order_amount || 0) + toNumber(data?.LoadFund?.fees || 0))}
        valueClassName={
          toNumber(data?.order_amount) >= toNumber(refund)
            ? ""
            : "text-green-500"
        }
      />
      <PaymentInformationRow
        label="TOTAL REFUND AMOUNT :"
        value={`- ${currencyFormat(toNumber(refund))}`}
        valueClassName="text-red-500"
      />


      <PaymentInformationRow
        label="TRANSACTION TYPE:"
        value={data?.payment_type}
      />
    </div>
  </div>
);

const PaymentInformationRow = ({ label, value, valueClassName }) => (
  <div className="grid items-center grid-cols-2 gap-5 md:px-5 py-1 text-sm ">
    <div className="text-xs font-semibold tracking-wider text-gray-500 uppercase">
      {label}
    </div>
    <div className={`text-sm font-semibold text-right ${valueClassName}`}>
      {value}
    </div>
  </div>
);

const LoadingState = () => (
  <div className="grid md:grid-cols-4 gap-x-7 xl:col-span-12 md:col-span-12 animate-pulse">
    <div className="col-span-2 md:mt-5">
      <div className="items-center px-8 py-5 mb-3 bg-white rounded-lg shadow-lg shadow-slate-100">
        <div className="flex items-center justify-between pb-3 border-b">
          <p className="w-1/4 h-4 mb-3 rounded bg-slate-200"></p>
          <div className="px-10 py-4 mb-3 rounded bg-slate-200"></div>
        </div>
        <div className={`flex justify-between items-center mt-4`}>
          <div className={`flex items-center`}>
            <div
              className={`lg:w-20 lg:h-20 w-14 h-14 lg:p-5 p-3 rounded-full bg-slate-200`}
            ></div>
            <div className="pl-2 md:pl-5 ml-2 md:ml-5 border-l">
              <span className="inline-block h-4 rounded w-36 bg-slate-200"></span>{" "}
              <br />
              <span className="inline-block w-10 h-2 mt-1 rounded bg-slate-200"></span>
            </div>
          </div>
          <div className="">
            <span className="inline-block h-3 rounded w-36 bg-slate-200"></span>{" "}
            <br />
            <span className="inline-block w-10 h-2 mt-1 rounded bg-slate-200"></span>
          </div>
        </div>
      </div>
      <p className="inline-block h-3 mt-3 mb-2 rounded w-36 bg-slate-200"></p>
      <div className="border-t">
        <ul className={styles.timeline_step}>
          <li className="flex flex-row items-center pt-4 pb-4">
            <div className="w-8 h-8 p-1 rounded-full bg-slate-200"></div>
            <div className="ml-2 mr-6">
              <span className="inline-block h-3 rounded w-36 bg-slate-200"></span>{" "}
              <br />
              <span className="inline-block w-10 h-2 mt-1 rounded bg-slate-200"></span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div className="col-span-2">
      <div className="p-6 py-4 mt-5 rounded-lg bg-slate-100 bg-opacity-40 animate-pulse">
        <div className="flex items-center justify-between">
          <p className="w-2/4 h-4 mb-3 rounded bg-slate-300"></p>
          <div className="px-10 py-4 mb-3 rounded bg-slate-300"></div>
        </div>
        <div className="p-5 text-gray-600 bg-white rounded">
          {Array.from(Array(5), (e, i) => (
            <div className="space-y-3 mt-2" key={i}>
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const TransactionDetails = ({ permission }) => {
  const { selectedStoreIds, token, refundableToCard } = useAuth();
  const [state, setState] = useState({
    isOpenModal: false,
    lastTransition: null,
    otherTransactions: null,
    chargebacks: null,
    customerExternalId: null,
    refund: null,
    date: null,
    orderId: null,
    associateName: null,
    data: null,
    resApiData: false,
    currentStoreId: selectedStoreIds,
    customerExtId: null,
    refundType: null,
    showComplianceFee: false,
    complianceFee: null,
  });
  const { pathname } = useLocation();
  const getTransactionId = pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      currentStoreId: selectedStoreIds,
      refundType: null,
      isOpenModal: false,
      showComplianceFee: false,
      complianceFee: "",
    }));
  }, [selectedStoreIds]);

  useEffect(() => {
    if (state.currentStoreId) {
      getTransactions()
    }
  }, [getTransactionId, state.currentStoreId, token]);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const response = await Axios(token).post(
        `/transaction/details/${getTransactionId}`,
        { stores: state.currentStoreId },
      );

      const responseData = response.data.response;
      setState((prev) => ({
        ...prev,
        chargebacks: responseData?.ChargebackActivities || [],
        customerExtId: responseData?.Customer?.customer_external_id,
        data: responseData,
        date: convertToLocalTime(responseData.createdAt, "PPP"),
        orderId: responseData.order_id,
        associateName: `${responseData?.Associate?.first_name} ${responseData?.Associate?.last_name}`,
        customerExternalId: responseData?.Customer?.customer_external_id,
        otherTransactions: formatOtherTransactions(
          responseData.OtherTransactions,
          responseData?.LoadFund?.fees,
        ),
        refund: calculateRefund(responseData.OtherTransactions),
        resApiData: true
      }));
      if (responseData.Customer && responseData.Customer.customer_external_id) {
        const lastTransitionResponse = await Axios(token).post(
          `/customer/transctions/dropdown/${responseData.Customer.customer_external_id}`,
          { stores: state.currentStoreId },
        );
        setState((prev) => ({
          ...prev,
          lastTransition: formatLastTransitions(
            lastTransitionResponse.data.response,
          ),
          resApiData: false
        }));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setState((prev) => ({ ...prev, data: null, resApiData: false }));
    }
  };

  const formatOtherTransactions = (transactions, fee) => {
    return (
      transactions?.map((item) => ({
        amount: item.amount,
        date: item.createdAt,
        type: item.type,
        status: item.status,
        refund_for: item.refund_for,
        refund_type: item.refund_type,
        fee: fee,
      })) || []
    );
  };

  const calculateRefund = (transactions) => {
    return transactions.reduce((acc, item) => acc + toNumber(item.amount), 0);
  };

  const formatLastTransitions = (transitions) => {
    return (
      transitions?.map((item, index) => ({
        id: index + 1,
        amount: item.order_amount,
        date: convertToLocalTime(item.createdAt, "PPP"),
        external_id: item.transactions_external_id,
      })) || []
    );
  };

  const handleRefund = (type) => {
    setState((prev) => ({
      ...prev,
      refundType: type,
      isOpenModal: true,
    }));
  };

  const modalCallBack = async () => {
    setState((prev) => ({
      ...prev,
      isOpenModal: false,
      refundType: "",
    }));
    await getTransactions();
  };

  const handleNavigatePage = () => {
    navigate(`/customer/${state.customerExternalId}`);
  };

  return (
    <CompanyLayout
      onStoreChange={(val) =>
        setState((prev) => ({ ...prev, currentStoreId: val }))
      }
    >
      <div>
        { loading ? (
          <LoadingState />
        ) : (
          <>
            {state.data ? (
              <div className="grid grid-cols-1 md:grid-cols-12">
                {HasPermission(permission) ? (
                  <>
                    {state.data && (
                      <TransactionDetailsContent
                        data={state.data}
                        orderId={state.orderId}
                        associateName={state.associateName}
                        date={state.date}
                        chargebacks={state.chargebacks}
                        otherTransactions={state.otherTransactions}
                        refund={state.refund}
                        refundableToCard={refundableToCard}
                        handleRefund={handleRefund}
                        lastTransition={state.lastTransition}
                        handleNavigatePage={handleNavigatePage}
                        showComplianceFee={state.showComplianceFee}
                        complianceFee={state.complianceFee}
                        setShowComplianceFee={(value) =>
                          setState((prev) => ({
                            ...prev,
                            showComplianceFee: value,
                          }))
                        }
                      />
                    )}
                  </>
                ) : (
                  <Unauthorised />
                )}
              </div>
            ) : <NoData />
            }
          </>
        ) }

      </div>
      {state.isOpenModal && HasPermission(["perform_refund"]) && (
        <RefundModal
          orderId={state.orderId}
          orderAmount={state.data?.order_amount}
          externalId={getTransactionId}
          isModalOpen={state.isOpenModal}
          modalCallBack={modalCallBack}
          custExtId={state.customerExtId}
          setIsOpenModal={(value) =>
            setState((prev) => ({ ...prev, isOpenModal: value }))
          }
          refundableToCard={refundableToCard}
          refundType={state.refundType}
        />
      )}
    </CompanyLayout>
  );
};

export default TransactionDetails;
