import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import styles from "../Scss/Admin.module.scss";
import { toNumber } from "lodash";
import Loader from "../Global/widget/Loader";
import Modal from "./modal";
import Axios from "../../Config/AxiosInstance";

export default function RefundModal({
  isModalOpen,
  modalCallBack,
  externalId,
}) {
  const { token } = useAuth();
  const [showModal, setShowModal] = useState(isModalOpen);
  const [refundAmount, setRefundAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      setShowModal(true);
      Axios(token)
        .get(`/get/refund/data/${externalId}`)
        .then((response) => {
          setLoading(false);
          let amount = response?.data?.response?.remainingAmount || 0;
          setRefundAmount(amount);
        })
        .catch((e) => {
          console.log(e.response);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const [state, setState] = useState({
    amount: "",
    reason: null,
    details: "",
  });
  const { amount, reason, details } = state;
  const modalIsClose = () => {
    setShowModal(false);
    modalCallBack(false);
  };

  const onReasonChange = (e) => {
    setState({ ...state, reason: e.target.value });
  };

  const OnChangeHandel = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const handleRefundToCard = async () => {
    if (amount === "" || amount === null) {
      setIsErrorModalOpen(true);
      setError("Please enter amount to refund.");
      return;
    }
    if (reason === null) {
      setIsErrorModalOpen(true);
      setError("Please select reason.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const payload = {
        transaction_id: externalId,
        comment: details,
        reason: reason,
        amount: amount,
      };
      await Axios(token).post("/create/refundToCard", payload);
      setLoading(false);
      if (modalCallBack) {
        modalCallBack();
      }
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setError(
        error?.response?.data?.response?.message ||
          error?.response?.data?.message ||
          "We cannot process your request at this moment.",
      );
      setIsErrorModalOpen(true);
    }
  };

  return (
    <>
      {showModal ? (
        <Fragment>
          {isErrorModalOpen && (
            <Modal
              CloseOnClick={() => setIsErrorModalOpen(false)}
              isModalOpen={isErrorModalOpen}
              showCancel={false}
              modalCallBack={() => setIsErrorModalOpen(false)}
              type={"Error"}
              message={error}
              zIndex={50}
            />
          )}
          {loading && <Loader />}

          <div
            className={`${
              isErrorModalOpen ? "z-500" : "z-50"
            } fixed inset-0  flex items-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none`}
          >
            <div className="relative mx-auto my-6 w-11/12 md:w-2/4 lg:w-2/6 xl:w-96">
              <button
                onClick={modalIsClose}
                className="absolute z-10 text-gray-400 top-3 right-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <form className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-2xl outline-none focus:outline-none">
                <div className="relative flex-auto px-6 pt-6">
                  <div>
                    <h5 className={`text-lg font-semibold text-gray-700`}>
                      Refund Payment{" "}
                    </h5>
                    <p className={`text-sm text-gray-600 mt-1 pb-2 border-b`}>
                      Total refundable amount {": "}
                      <span className="font-semibold">
                        ${toNumber(refundAmount).toFixed(2)}
                      </span>{" "}
                    </p>

                    <div>
                      <div className="mt-3">
                        <label className="block pb-1 text-sm text-gray-500">
                          Enter Amount
                        </label>
                        <input
                          type="number"
                          value={amount}
                          step={0.2}
                          onChange={OnChangeHandel}
                          name={`amount`}
                          id={"amount"}
                          className={`${styles.form_control_md} placeholder:font-normal text-sm placeholder:text-gray-300`}
                        />
                      </div>

                      <div className="mt-3">
                        <label className="block pb-1 text-sm text-gray-500">
                          Reason
                        </label>
                        <select
                          value={reason || ""}
                          onChange={onReasonChange}
                          name="reason"
                          className={`${styles.form_control_md} outline-none`}
                        >
                          <option value={""}>Select Reason</option>
                          <option value={"duplicate"}>Duplicate</option>
                          <option value={"fraudulent"}>Fraudulent</option>
                          <option value={"requested_by_customer"}>
                            Requested by customer
                          </option>
                          <option value={"other"}>Other</option>
                        </select>
                      </div>

                      <div className="mt-3">
                        <label className="block pb-1 text-sm text-gray-500">
                          Details
                        </label>
                        <textarea
                          value={details}
                          onChange={OnChangeHandel}
                          name={`details`}
                          id={"details"}
                          className={`${styles.form_control_md} placeholder:font-normal placeholder:text-gray-300 text-sm`}
                          placeholder="Details"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center px-6 pb-8 mt-5 border-solid rounded-b border-blueGray-200">
                  <button
                    className="px-6 md:px-6 py-1.5 md:py-2 mb-1 mr-1 text-sm font-medium text-gray-600 transition-all duration-150 ease-linear rounded outline-none bg-slate-100 focus:outline-none"
                    type="button"
                    onClick={modalIsClose}
                  >
                    {" "}
                    Close
                  </button>
                  <button
                    className="px-4 md:px-6 py-1.5 md:py-2 mb-1 mr-1 text-sm font-medium text-white transition-all duration-150 ease-linear disabled:bg-gray-400 disabled:cursor-no-drop bg-green-500 rounded outline-none focus:outline-none"
                    type="button"
                    onClick={handleRefundToCard}
                  >
                    Refund
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="fixed inset-0 z-40 backdrop-blur-sm bg-white/30" />
        </Fragment>
      ) : null}
    </>
  );
}
