import React, {memo, useCallback, useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileDropdown from "../../Global/widget/profileDropdown";
import NavBar from "./NavBar";
import { Listbox } from "@headlessui/react";
import { useAuth } from "../../../Context/AuthContext";
import TerminalModal from "../../Global/widget/TerminalModal";
import Loader from "../../Global/widget/Loader";
import StoreModal from "../../Global/widget/StoreModal";
import QRCode from "qrcode";
import Modal from "../../../Components/Modal/modal";
import GainLogo from "../../Global/Gainlogo";
import Axios from "../../../Config/AxiosInstance";
import { isEmpty } from "lodash";
import StoreInfoModal from "../../Global/widget/StoreInfoModal";
import clsx from "clsx";
// import {ChevronDownIcon} from "lucide-react";

const InfoButton = ({onClick}) => (
  <button
    className="bg-green-500 text-white hover:bg-green-700 rounded px-3 py-1.5 md:py-2 leading-5 transition-all text-xs"
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 50 50"
      className="!text-white w-5"
      fill="#fff"
    >
      <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
    </svg>
  </button>
);

const QRCodeIcon = ({ className, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    onClick={onClick}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
    />
  </svg>
);

const ChevronDownIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

// Constants
const STORE_QR_URLS = {
  localhost: 'http://localhost:3331/qrpay',
  'mirror-terminal': 'https://mirror-customer.gainfunds.com/qrpay',
  'dev-terminal': 'https://dev-customer.gainfunds.com/qrpay',
  default: 'https://gainfunds.com/qrpay'
};

// Custom hooks
const useStoreManagement = (userInfo, onStoreChange) => {
  const {
    setStoreId,
    setCompanyId,
    setIsAllStoreSelected,
    setSelected,
    setSelectedStoreIds
  } = useAuth();

  const initializeStoreSelection = useCallback((stores) => {
    if (!stores?.length) return;

    if (stores.length > 1) {
      const storeArray = stores.map(store => store.id);
      setIsAllStoreSelected(true);
      setSelected('All');
      setSelectedStoreIds(storeArray);
    } else {
      setSelected(stores[0]?.name);
      setSelectedStoreIds([stores[0]?.id]);
      setIsAllStoreSelected(false);
    }
  }, [setIsAllStoreSelected, setSelected, setSelectedStoreIds]);

  const handleStoreChange = useCallback((value) => {
    if (isEmpty(value)) return;

    const allStores = userInfo?.stores || [];

    if (value === 'all') {
      const storeArray = allStores.map(store => store.id);
      setIsAllStoreSelected(true);
      setSelected('All');
      setSelectedStoreIds(storeArray);
    } else {
      const selectedStore = allStores.find(store => store.id === value);
      setIsAllStoreSelected(false);
      setStoreId(value);
      setCompanyId('');
      setSelected(selectedStore?.name);
      setSelectedStoreIds([value]);
    }

    onStoreChange?.([value]);
  }, [userInfo, setStoreId, setCompanyId, setIsAllStoreSelected, setSelected, setSelectedStoreIds, onStoreChange]);

  return { handleStoreChange, initializeStoreSelection };
};

const useTerminalData = (token, selectedStoreIds) => {
  const [terminalData, setTerminalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTerminalData = useCallback(async () => {
    if (!selectedStoreIds) return;

    setLoading(true);
    try {
      const response = await Axios(token).get(`/all-terminals/${selectedStoreIds}`);
      setTerminalData(response.data?.response || []);

    } catch (error) {
      console.error('Error fetching terminal data:', error);
      setTerminalData([]);
    } finally {
      setLoading(false);
    }
  }, [token, selectedStoreIds]);

  return { terminalData, loading, fetchTerminalData };
};

const Header = memo(({ onStoreChange, isthisTranstionDetailsPage }) => {
  const {
    userInfo,
    storeId,
    token,
    companyId,
    isAllStoreSelected,
    selected,
    selectedStoreIds,
    setCreateIsFormDisable,
    createIsFormDisable,
    setIsAssociateAuthenticated,
    setCurrentAssociatePin,
    setInTerminalPermissions,
    setInTerminalInfo
  } = useAuth();

  // State
  const [userInfo_Store, setUserInfo_Store] = useState([]);
  const [modalStates, setModalStates] = useState({
    store: false,
    terminal: false,
    qr: false,
    storeInfo: false
  });
  const [QRCodeData, setQRCodeData] = useState(null);

  const location = useLocation();
  const { handleStoreChange, initializeStoreSelection } = useStoreManagement(userInfo, onStoreChange);
  const { terminalData, loading, fetchTerminalData } = useTerminalData(token, selectedStoreIds);

  // Effects
  useEffect(() => {
    if (!userInfo?.stores) return;

    const allStores = [...userInfo.stores].sort((a, b) =>
      a.account_number - b.account_number
    );

    setUserInfo_Store(allStores);
    resetAssociateState();
    initializeStoreSelection(allStores);
  }, [userInfo]);

  useEffect(() => {
    const isMultiStoreDisabled = selectedStoreIds?.length > 1 &&
      ['terminal', 'associate'].includes(location.pathname.split('/')[2]);

    setCreateIsFormDisable(isMultiStoreDisabled);
  }, [selectedStoreIds, location, setCreateIsFormDisable]);

  // Handlers
  const toggleModal = useCallback((modalName) => {
    setModalStates(prev => ({
      ...prev,
      [modalName]: !prev[modalName]
    }));
  }, []);

  const handleQRGeneration = useCallback(async () => {
    toggleModal('qr');
    try {
      const origin = window.location.origin;
      const baseUrl = STORE_QR_URLS[origin.includes('localhost') ? 'localhost' :
        origin.includes('mirror-terminal') ? 'mirror-terminal' :
          origin.includes('dev-terminal') ? 'dev-terminal' : 'default'];

      const qrData = await QRCode.toDataURL(
        `${baseUrl}/${selectedStoreIds}`,
        { version: 10 }
      );
      setQRCodeData(qrData);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  }, [selectedStoreIds]);

  const resetAssociateState = useCallback(() => {
    setIsAssociateAuthenticated(false);
    setInTerminalPermissions(null);
    setInTerminalInfo(null);
    setCurrentAssociatePin('');
  }, [setIsAssociateAuthenticated, setInTerminalPermissions, setInTerminalInfo, setCurrentAssociatePin]);

  // Render helpers
  const renderStoreSelector = () => (
    !isthisTranstionDetailsPage && userInfo?.stores && (
      <Listbox
        value={isAllStoreSelected ? `company:${companyId}` : `store:${storeId}`}
        onChange={handleStoreChange}
      >
        <div className="relative mt-1">
          <Listbox.Button
            className={clsx(
              'relative w-20 lg:w-auto md:w-auto py-2 pl-3 pr-10 border rounded text-left',
              'text-gray-500 font-semibold cursor-pointer focus:outline-none',
              'text-sm md:text-sm',
              {
                'border-green-300 shadow-lg shadow-green-200': createIsFormDisable,
                'border-gray-200': !createIsFormDisable
              }
            )}
            onClick={() => toggleModal('store')}
          >
            <span className="block truncate">{selected}</span>
            <ChevronDownIcon className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none top-1 w-4 h-4" />
          </Listbox.Button>
        </div>
      </Listbox>
    )
  );

  const renderTerminalButton = (permissions) => {
    const hasTerminalPermission = [
      'terminal',
      'make_standard_payment',
      'make_contactless_payment',
      'make_all_payments'
    ].some(permission => permissions.includes(permission));

    if (!hasTerminalPermission) return null;

    return isAllStoreSelected ? (
      <button
        className="bg-green-200 text-white rounded px-3 py-1.5 md:py-2 leading-5 transition-all text-xs"
        disabled
      >
        Terminal
      </button>
    ) : (
      <div className="gap-2 flex items-center">
        <QRCodeIcon
          className="w-8 h-8 text-green-400 cursor-pointer"
          onClick={handleQRGeneration}
        />
        <button
          onClick={async () => {
            await fetchTerminalData();
            toggleModal('terminal');
          }}
          className="bg-green-500 text-white hover:bg-green-700 rounded px-3 py-1.5 md:py-2 leading-5 transition-all text-xs"
        >
          Terminal
        </button>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}

      <header className="shadow-sm flex-shrink-0 backdrop-blur-xl relative z-[99999] bg-white p-0 lg:px-5 lg:py-2">
        <div className="flex items-center justify-between px-2 lg:px-5 w-full">
          <div className="flex items-center space-x-3">
            <Link to="/dashboard" className="brandLogo">
              <GainLogo classes="w-24" />
            </Link>
            {renderStoreSelector()}
            <InfoButton onClick={() => toggleModal('storeInfo')} />
          </div>

          <nav className="hidden xl:flex ml-auto pr-5">
            <NavBar />
          </nav>

          <div className="relative z-[9] flex items-center space-x-3">
            {renderTerminalButton(userInfo?.permissions || [])}
            <ProfileDropdown data={true} />
          </div>
        </div>
      </header>

      <StoreModal
        show={modalStates.store}
        onStoreModalClose={() => toggleModal('store')}
        onSelectStore={(val) => {
          handleStoreChange(val);
          toggleModal('store');
        }}
        data={userInfo_Store}
      />

      <TerminalModal
        show={modalStates.terminal}
        onModalClick={() => toggleModal('terminal')}
        data={terminalData}
      />

      <Modal
        isModalOpen={modalStates.qr}
        showOk
        modalCallBack={() => toggleModal('qr')}
        type="Scan QR code to proceed"
        message="Click on QR to download it"
        qrCode={QRCodeData}
      />

      <StoreInfoModal
        show={modalStates.storeInfo}
        data={userInfo_Store}
        onStoreModalClose={() => toggleModal('storeInfo')}
      />
    </>
  );
});

Header.displayName = 'Header';

export default Header;
