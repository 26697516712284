import React, { useEffect, useState } from "react";
import styles from "../../../Components/Scss/Admin.module.scss";
import { useAuth } from "../../../Context/AuthContext";
import Table from "../../../Components/Global/Table";
import { HasPermission } from "../../../Components/Global/Permission";
import Unauthorised from "../../Global/Unauthorised";
import CompanyLayout from "../../../Components/Company/Layouts";
import Filters from "../../../Components/Global/transaction/Filters";
import Axios from "../../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";
import download from "downloadjs";
import DropdownModal from "../../../Components/Modal/DropdownModal";
import Loader from "../../../Components/Global/widget/Loader";
import convertToLocalTime from "../../../Utils/timeZoneConverter";
import useWebSocket from "../../../hooks/websocket";

export const Title = ({ name }) => {
  return (
    <>
      <span className={`flex`}>
        <span className={`mr-1`}>{name}</span>
        <span>
          <svg
            height="10"
            width="10"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 45 45"
          >
            <g>
              <rect
                fill="#1c8e44"
                height="23"
                transform="matrix(-0.7071 -0.7072 0.7072 -0.7071 38.2666 48.6029)"
                width="11"
                x="23.7"
                y="4.875"
              />
              <path
                d="M44.087,3.686l-2.494-2.494c-1.377-1.377-3.61-1.377-4.987,0L34.856,2.94l7.778,7.778l1.749-1.749   C45.761,7.593,45.465,5.063,44.087,3.686z"
                fill="#231F20"
              />
              <polygon fill="#231F20" points="16,22.229 16,30 23.246,30  " />
              <path
                d="M29,40H5V16h12.555l5-5H3.5C1.843,11,0,11.843,0,13.5v28C0,43.156,1.843,45,3.5,45h28   c1.656,0,2.5-1.844,2.5-3.5V23.596l-5,5V40z"
                fill="#231F20"
              />
            </g>
          </svg>
        </span>
      </span>
    </>
  );
};

function CompanyContactless({ permission }) {
  const route = useNavigate();
  const { token, selectedStoreIds, userInfo } = useAuth();
  const [transaction, setTransaction] = useState([]);
  const [datePick, setDatePick] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState("all");
  const [currentStoreId, setCurrentStoreId] = useState(selectedStoreIds);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState();
  const [to, setTo] = useState(
    convertToLocalTime().endOf("day").format("YYYY-MM-DD"),
  );
  const [from, setFrom] = useState(
    convertToLocalTime().startOf("day").format("YYYY-MM-DD"),
  );
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [driver, setDriver] = useState([]);
  const [existingDriver, setExistingDriver] = useState("");
  const [date, setData] = useState([]);
  const [type, setType] = useState("");
  const [refresh, setRefresh] = useState(false);

  const { status, error, subscribe } = useWebSocket(
    token,
    [`contactless:${userInfo?.company_id}`]
  );

  const columns = [
    {
      name: "Link Sent At",
      selector: (row) => convertToLocalTime(row.date).format("PPp"),
      width: "250px",
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
    },
    {
      name: <Title name={"Employee"} />,
      selector: (row) => row.associate,
      cell: (row) => [
        <button
          key={row.id + 1}
          className={`text-gray-500 px-3 py-1 tableBtnHover leading-5 rounded-full mr-3 flex text-nowrap`}
          onClick={() => updateDriver(row, "budtender")}
          id={"" + row.id + 1}
        >
          <span className={`w-full`}>{row.associate}</span>
        </button>,
      ],
    },
    {
      name: <Title name={"Driver"} />,
      selector: (row) => row.driver,
      cell: (row) => [
        <button
          key={row.id + 1}
          className={`text-gray-500 px-3 py-1 tableBtnHover leading-5 rounded-full mr-3 flex`}
          onClick={() => updateDriver(row, "driver")}
          id={"" + row.id + 1}
        >
          <span className={`w-full`}>{row.driver}</span>
        </button>,
      ],
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      wrap: true,
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(5,66,120,0.0509803922)",
          color: "#054278",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
          minWidth: "75px",
        },
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "150px",
      conditionalCellStyles: [
        {
          when: (row) => row.status === "PAID",
          style: {
            color: "#1c8e44",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.status === "PENDING",
          style: {
            color: "#860808",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.status === "EXPIRED",
          style: {
            color: "red",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "completed or expired At",
      selector: (row) => convertToLocalTime(row.expiredAt).format("PPp"),
      width: "250px",
    },
    {
      name: "Last4",
      selector: (row) => row.last4,
    },
    {
      name: "Source",
      selector: (row) => row.source,
      conditionalCellStyles: [
        {
          when: (row) => row.source === "API",
          style: {
            fontWeight: "bold",
            color: "#000000",
          },
        },
      ],
    },
  ];
  const transactionColumns = [
    {
      name: "Date",
      selector: (row) => convertToLocalTime(row.date).format("PPp"),
      width: "250px",
      align: "left",
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
    },
    {
      name: <Title name={"Employee"} />,
      selector: (row) => row.associate,
      cell: (row) => [
        <button
          key={row.id + 1}
          className={`text-gray-500 px-3 py-1 tableBtnHover leading-5 rounded-full mr-3 flex`}
          onClick={() => updateDriver(row, "budtender")}
          id={"" + row.id + 1}
        >
          <span className={`w-full`}>{row.associate}</span>
        </button>,
      ],
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      wrap: true,
      style: {
        paddingTop: "0",
        paddingBottom: "0",
        div: {
          background: "rgba(5,66,120,0.0509803922)",
          color: "#054278",
          fontWeight: "bolder",
          borderRadius: "1.5rem",
          padding: "5px 15px",
          minWidth: "75px",
        },
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "150px",
      conditionalCellStyles: [
        {
          when: (row) => row.status === "PAID",
          style: {
            color: "#1c8e44",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.status === "PENDING",
          style: {
            color: "#860808",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.status === "EXPIRED",
          style: {
            color: "red",
            justifyContent: "center",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Last4",
      selector: (row) => row.last4,
    },
    {
      name: "Source",
      selector: (row) => row.source,
      conditionalCellStyles: [
        {
          when: (row) => row.source === "API",
          style: {
            fontWeight: "bold",
            color: "#000000",
          },
        },
      ],
    },
    {
      name: "API Order ID",
      selector: (row) => row.external_order_id,
    },
  ];

  const updateDriver = async (row, type) => {
    let data = {
      store_id: row.storeId,
    };
    setType(type);
    setData(row);
    setExistingDriver(type === "driver" ? row.driverId : row.associateId);
    setDownloadLoading(true);
    let url;
    if (type === "driver") {
      url = "/get/driver/list";
    } else {
      url = "/budtender/list";
    }
    try {
      const driverList = await Axios(token).post(url, data);
      setDriver(driverList?.data?.response);
      setModalOpen(true);
      setDownloadLoading(false);
    } catch (e) {
      setDownloadLoading(false);
    }
  };
  const quarries = {
    stores: currentStoreId,
    s: search,
    days: "",
    from: from,
    to: to,
    status: transactionStatus,
    page: currentPage,
    size: perPage,
    type: "store",
  };
  const getTransactions = async () => {
    try {
      setLoading(true);
      const response = await Axios(token).post(
        `/dashboard/contactless`,
        quarries,
      );
      if (response?.data?.response) {
        let transactions = response.data.response;
        await filterData(transactions);
      }
      setRefresh(false);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getTransactionsForPusher = async () => {
    try {
      const response = await Axios(token).post(
        `/dashboard/contactless`,
        quarries,
      );
      if (response?.data?.response) {
        let transactions = response.data.response;
        await filterData(transactions);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log(status, error)
    if (status === 'connected') {
      const unsubscribe = subscribe('payment:updated', () => { //data
        getTransactionsForPusher()
      });

      return () => unsubscribe();
    }
  }, [status, subscribe]);

  const filterData = (transactions) => {
    let transactionData = transactions.records;
    let transactionArray = [];
    transactionData.map((data) => {
      transactionArray.push({
        serialNo: data?.serialNo,
        id: data?.id,
        transactionId: data?.transactionId,
        date: convertToLocalTime(data?.date).format("PPP"),
        customerName: data?.customerName || "-",
        phone: data?.phone || "-",
        amount: data?.amount,
        status: data?.status,
        associate: data?.associate || "-",
        driver: data?.driver || "-",
        driverId: data?.driverId,
        associateId: data?.associateId,
        storeId: data?.storeId,
        expiredAt: data?.expiredAt,
        last4: data?.last4 || "-",
        source: data?.source || "PE",
        external_order_id: data?.external_order_id || "-",
      });
    });
    setTransaction(transactionArray);
    setTotalRows(transactions.totalItems);
  };

  useEffect(() => {
    if (token && currentStoreId) {
      getTransactions().then();
    }
  }, [
    token,
    datePick,
    search,
    currentPage,
    currentStoreId,
    transactionStatus,
    perPage,
    refresh,
  ]);

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      const data = await Axios(token).post(
        "/download/transaction/report",
        quarries,
        {
          responseType: "blob",
        },
      );
      setDownloadLoading(false);
      download(
        data.data,
        `Payments transactions reports ${convertToLocalTime(new Date()).format(
          "MMMM Do YYYY",
        )}`,
      );
    } catch (e) {
      setDownloadLoading(false);
    }
  };
  const handleDateChange = async (val) => {
    setDatePick(val);
    setFrom(
      convertToLocalTime(val[0])
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
    setTo(
      convertToLocalTime(val[1]).endOf("day").format("YYYY-MM-DD hh:mm:ss:SSS"),
    );
  };

  const rowClickHandler = (val) => {
    if (val?.status === "PAID" && val?.transactionId) {
      route(`/transaction/${val.transactionId}`);
    } else {
      const makeLink = `https://gainfunds.com/scan/${val.id}`;
      navigator.clipboard.writeText(makeLink);
      setIsLinkCopied(true);
    }
  };
  setTimeout(() => {
    setIsLinkCopied(false);
  }, 1000);

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(currentPage);
  };
  return (
    <div>
      {downloadLoading && <Loader />}
      {modelOpen && (
        <DropdownModal
          data={date}
          storeId={currentStoreId}
          drivers={driver}
          type={type}
          existingDriver={existingDriver}
          isContactless={transactionStatus !== "standard"}
          onSubmit={async () => {
            setModalOpen(false);
            await getTransactions();
          }}
          onClose={(val) => {
            setModalOpen(val);
            setLoading(false);
          }}
        />
      )}
      {HasPermission(permission) ? (
        <CompanyLayout
          onStoreChange={(val) => {
            setCurrentStoreId(val);
            setDriver([]);
          }}
        >
          <div className={`${styles.lightGrey}`}>
            {isLinkCopied && (
              <div
                className={`bg-white animate-bounce-up text-green-500 text-sm font-semibold fixed animate-fade bottom-10 z-50 text-center rounded px-5 py-3 shadow-lg shadow-green-200 inline-block left-1/2`}
              >
                Link Copied!
              </div>
            )}
            <div>
              <Filters
                isDashboard={true}
                download={false}
                downloadBtn={false}
                placeHolder={"Customer Name"}
                onDownload={handleDownload}
                date={handleDateChange}
                paymentMenu={true}
                paymentMenuKey={[
                  { name: "All", key: "all" },
                  {
                    name: "Paid",
                    key: "PAID",
                  },
                  { name: "Pending", key: "PENDING" },
                  { name: "Expired", key: "EXPIRED" },
                  // { name: "API - Standard", key: "standard" },
                ]}
                onMenuClick={(key) => setTransactionStatus(key)}
                currentMenuItem={transactionStatus}
                onSearchSubmit={(val) => setSearch(val)}
                onSearchReset={() => setSearch("")}
                downloadBtnDisable={!transaction.length}
                createdAt={new Date()}
                refresh={true}
                onRefreshClick={(val) => setRefresh(val)}
              />
              <div className={`mt-5 ${styles.table_ui_style}`}>
                {transactionStatus === "standard" ? (
                  <Table
                    data={transaction}
                    columns={transactionColumns}
                    perPage={perPage}
                    totalRows={totalRows}
                    pending={loading}
                    showPointer={true}
                    onRowClick={rowClickHandler}
                    onPageChange={(val) => setCurrentPage(val)}
                    onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                      handleRowsPerPageChange(currentRowsPerPage, currentPage)
                    }
                  />
                ) : (
                  <Table
                    data={transaction}
                    columns={columns}
                    perPage={perPage}
                    totalRows={totalRows}
                    pending={loading}
                    showPointer={true}
                    onRowClick={rowClickHandler}
                    onPageChange={(val) => setCurrentPage(val)}
                    onRowPerPageChange={(currentRowsPerPage, currentPage) =>
                      handleRowsPerPageChange(currentRowsPerPage, currentPage)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </CompanyLayout>
      ) : (
        <Unauthorised />
      )}
    </div>
  );
}

export default CompanyContactless;
