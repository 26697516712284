import React, { useEffect, useState } from "react";
import { CheckCircle, XCircle, Download, X} from "lucide-react";

export default function Modal({
                                isModalOpen,
                                message,
                                type = "Success",
                                showType = true,
                                modalCallBack,
                                showOk = true,
                                showCancel,
                                cancelBtnText,
                                showOkText,
                                CloseOnClick,
                                zIndex = 999,
                                dntShowIcon,
                                qrCode,
                                customContent,
                                headerIcon,
                                customButtons,
                                className = "",
                                contentClassName = "",
                                buttonClassName = "",
                                hideCloseButton
                              }) {
  const [showModal, setShowModal] = useState(isModalOpen);
  useEffect(() => {
    if (isModalOpen) {
      setShowModal(true);
    }
  }, [isModalOpen]);

  const modalIsClose = () => {
    setShowModal(false);
    if (modalCallBack) {
      modalCallBack(false);
    }
  };

  const handleCloseOnClick = () => {
    setShowModal(false);
    if (CloseOnClick) {
      CloseOnClick(false);
    }
  };

  const handleDownload = () => {
    if (qrCode) {
      const link = document.createElement("a");
      link.download = "QRCode.png";
      link.href = qrCode;
      link.click();
    }
  };

  const getStatusColor = () => {
    switch (type?.toLowerCase()) {
      case 'error':
      case 'danger':
        return 'text-red-600 bg-red-50';
      case 'warning':
        return 'text-yellow-600 bg-yellow-50';
      case 'info':
        return 'text-blue-600 bg-blue-50';
      default:
        return 'text-green-600 bg-green-50';
    }
  };

  if (!showModal) return null;



  return (
    <div className="fixed inset-0 z-[10000] overflow-y-auto">
      <div className="flex min-h-full items-end justify-center sm:items-center p-4">
        <div
          className={`fixed inset-0 ${
            zIndex ? `z-[${zIndex - 1}]` : "z-[55]"
          } bg-black/40 backdrop-blur-sm transition-opacity`}
        />

        <div
          className={`relative transform overflow-hidden rounded-t-2xl sm:rounded-xl bg-white transition-all w-full ${
            qrCode ? 'sm:max-w-xl' : 'sm:max-w-lg'
          } ${className}`}
        >
          {/* Close button */}
          {!hideCloseButton && (
            <button
              onClick={modalIsClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-full p-1"
            >
              <X size={20} />
            </button>
          )}

          {/* Icon */}
          {(!dntShowIcon || headerIcon) && (
            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full mt-8 mb-2">
              {headerIcon ? (
                headerIcon
              ) : (
                <div className={`p-3 rounded-full ${getStatusColor()}`}>
                  {type?.toLowerCase() === "error" ? (
                    <XCircle size={32} strokeWidth={1.5} />
                  ) : (
                    <CheckCircle size={32} strokeWidth={1.5} />
                  )}
                </div>
              )}
            </div>
          )}

          {/* Content */}
          <div className={`px-6 pb-6 pt-2 ${contentClassName}`}>
            {showType && type && (
              <h3 className="text-xl font-semibold text-center text-gray-900 mb-4">
                {type}
              </h3>
            )}

            <div className="space-y-6">
              {message && (
                <p className="text-gray-600 leading-relaxed text-center">
                  {message}
                </p>
              )}

              {customContent}

              {/* QR Code Section */}
              {qrCode && (
                <div className="mt-6 flex flex-col items-center">
                  <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
                    <img
                      src={qrCode}
                      alt="QR Code"
                      className="w-full max-w-[250px] h-auto"
                    />
                  </div>
                  <button
                    onClick={handleDownload}
                    className="bg-green-600 mt-4 inline-flex items-center font-medium text-sm text-white px-4 py-2 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors"
                  >
                    <Download  className="mr-1.5 w-6" />
                    Download QR Code
                  </button>
                </div>
              )}
            </div>

            {/* Buttons */}
            {(showOk || showCancel || customButtons) && (
              <div className={`mt-8 flex gap-3 justify-center ${buttonClassName}`}>
                {customButtons ? (
                  customButtons
                ) : (
                  <>
                    {showCancel && (
                      <button
                        className="inline-flex justify-center rounded-lg px-4 py-2.5 text-sm font-semibold bg-white border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
                        type="button"
                        onClick={handleCloseOnClick}
                      >
                        {cancelBtnText || "Cancel"}
                      </button>
                    )}

                    {showOk && (
                      <button
                        className="inline-flex justify-center rounded-lg px-4 py-2.5 text-sm font-semibold bg-green-600 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:bg-green-600 focus:ring-offset-2 transition-colors"
                        type="button"
                        onClick={modalIsClose}
                      >
                        {showOkText || "Confirm"}
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
