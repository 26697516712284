import { useState, useEffect, useCallback, useRef } from 'react';
import {getAPIUrl} from "../Utils/Common";

const useWebSocket = (authToken, channels = []) => {
  const [status, setStatus] = useState('disconnected');
  const [error, setError] = useState(null);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const [lastMessage, setLastMessage] = useState(null);
  const handlersRef = useRef(new Map());

  // Store channels in a ref to avoid dependency changes
  const channelsRef = useRef(channels);
  channelsRef.current = channels;

  const connect = useCallback(() => {
    try {
      // Prevent multiple connection attempts
      if (wsRef.current?.readyState === WebSocket.CONNECTING) {
        return;
      }

      // Close existing connection if any
      if (wsRef.current?.readyState === WebSocket.OPEN) {
        return;
      }
      let URL = getAPIUrl();
      URL = URL.replace("http://", "ws://").replace("https://", "wss://");
      const wsUrl = `${URL}/ws?token=${authToken}`;

      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;

      ws.onopen = () => {
        console.log('connected....');
        setStatus('connected');
        setError(null);

        // Subscribe to channels immediately after connection
        channelsRef.current.forEach(channel => {
          ws.send(JSON.stringify({
            type: 'subscribe',
            channel
          }));
        });
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          setLastMessage(message);

          // Call specific handler if exists
          const handler = handlersRef.current.get(message.event);
          if (handler) {
            handler(message.data);
          }
        } catch (err) {
          console.error('Error parsing WebSocket message:', err);
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected:', event.code, event.reason);
        setStatus('disconnected');
        wsRef.current = null;

        // Only attempt to reconnect if we're not closing intentionally
        if (event.code !== 1000) {
          // Attempt to reconnect after 5 seconds
          reconnectTimeoutRef.current = setTimeout(() => {
            if (wsRef.current?.readyState !== WebSocket.OPEN) {
              connect();
            }
          }, 5000);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError('WebSocket connection error');
        setStatus('error');
      };
    } catch (err) {
      console.error('Failed to establish WebSocket connection:', err);
      setError('Failed to establish WebSocket connection');
      setStatus('error');
    }
  }, [authToken]); // Only depend on authToken

  // Subscribe to events
  const subscribe = useCallback((event, handler) => {
    handlersRef.current.set(event, handler);
    return () => handlersRef.current.delete(event);
  }, []);

  // Send message through WebSocket
  const send = useCallback((event, data, channel = null) => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({
        type: 'message',
        event,
        data,
        channel
      }));
    } else {
      setError('WebSocket is not connected');
    }
  }, []);

  // Connect on mount and cleanup on unmount
  useEffect(() => {
    if (authToken) {
      connect();
    }

    return () => {
      // Clear any pending reconnection attempts
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }

      // Close the connection properly
      if (wsRef.current) {
        wsRef.current.close(1000, 'Component unmounting');
        wsRef.current = null;
      }
    };
  }, [authToken, connect]);

  return {
    status,
    error,
    lastMessage,
    send,
    subscribe,
    reconnect: connect
  };
};

export default useWebSocket;
